<template>
	<BaseLoading v-if="isLoading" is-full-page />
	<PaymentChannelForm
		v-else
		:logo="data.logo"
		:name-en="data.nameEN"
		:name-th="data.nameTH"
		:status="data.isActive"
		:is-deletable="false"
		:is-submitting="isUpdating"
		is-edit
		@onSubmit="handleSubmit"
	/>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import PaymentChannelForm from '@/components/PaymentChannelForm.vue';

export default {
	name: 'PaymentSubgroupsChannelEdit',

	components: {
		PaymentChannelForm,
	},
	data() {
		return {
			isLoading: false,
			isUpdating: false,
			groupId: this.$route.params.id,
			methodId: this.$route.params.methodId,
		};
	},
	computed: {
		...mapState('payments', {
			paymentGroup: 'paymentGroup',
		}),
		...mapGetters({
			data: 'payments/paymentMethodDetails',
		}),
	},
	async created() {
		this.isLoading = true;

		await Promise.all([
			this.getPaymentMethod(this.methodId),
			this.getPaymentMethodGroup(this.groupId),
		]);

		this.setPageTitle({
			title: this.data.name,
			isShow: true,
		});

		this.setBreadcrumbs([
			{
				title: 'Payment settings',
				route: { name: 'PaymentList' },
			},
			{
				title: this.paymentGroup.data.name_en,
				route: {
					name: 'PaymentSetting',
					params: { id: this.groupId },
				},
			},
		]);
		this.isLoading = false;
	},
	methods: {
		...mapActions({
			getPaymentMethod: 'payments/getPaymentMethod',
			getPaymentMethodGroup: 'payments/getPaymentMethodGroup',
			updatePaymentMethod: 'payments/updatePaymentMethod',
			setBreadcrumbs: 'breadcrumb/setBreadcrumbs',
			setPageTitle: 'pageTitle/setPageTitle',
		}),

		async handleSubmit(params = {}) {
			this.isUpdating = true;
			await this.updatePaymentMethod({ id: this.methodId, params });
			this.isUpdating = false;
		},
	},
};
</script>
